<template>
<div class="pa-4">

  <div :class="[{'px-3' : SM} , {'px-6' : !SM}]"  v-if="selectedArticle.uuid">
    <h3 style="font-size : 16px" :style="`color:${wsDARKER}`" class="pb-4 "> {{ selectedArticle.title }} </h3>
    <ws-text-viewer
        :value="selectedArticle.content"
        font-size="14"
        is-public
        :video-height="!SM ? 248 : 148"
    />
  </div>


  <template v-else-if="!selectedCategory.value">
    <div v-for="(item , i) in items" :key="i">
      <support-navigation
          @select-item="handleSelect($event , item.text)"
          :title="item.text"
          :items="item.sub_categories.length > 0 ? item.sub_categories : item.topics"
          class="mb-4"
      />
    </div>
  </template>

  <template v-else>

    <support-navigation
        @select-item="handleSelect"
        :title="selectedCategory.text"
        :items="selectedCategory.topics"
        class="mb-4"
    />

  </template>




</div>
</template>

<script>
import supportNavigation from "@/modules/tech_support/components/UI/supportNavigation";
import {mapActions} from "vuex";
export default {
  name: "supportHome",
  components : {
    supportNavigation
  },
  props : {
    returnTrigger : {
      type : Number
    }
  },
  data() {
    return {
      items : [],
      faqItems : [
        { text : 'Як відбувається реєстрація студента' },
        { text : 'Чи можливо використовувати власну доменну адресу для публічного сайту ?' }
      ],
      selectedArticle : {},
      selectedCategory : {},
      selectedCategoryName : null
    }
  },
  watch : {
    returnTrigger() {
      this.handleReturn()
    }
  },
  methods : {
    ...mapActions('documentation', [
      'GET_CATEGORIES_PUBLIC',
      'GET_TOPIC_PUBLIC'
    ]),

    async openArticle(alias) {
      let result = await this.GET_TOPIC_PUBLIC(alias)
      if ( !result ) {
        return
      }
      this.selectedArticle = result
      this.$emit('update-header' , { name : this.selectedCategory.text , displayReturn : true , wide : true } )

    },

    handleReturn() {
      // Single Hiearchy

      if ( this.selectedArticle.uuid && this.selectedCategory.value ) {
        this.selectedArticle = {}
        this.$emit('update-header' , { name : this.selectedCategoryName , displayReturn : true } )
      } else if (this.selectedArticle.uuid && !this.selectedCategory.value) {
        this.selectedArticle = {}
        this.$emit('update-header' , {} )
      } else {
        // Complex Hierarchy
        this.selectedArticle = {}
        this.selectedCategory = {}
        this.selectedCategoryName = null
        this.$emit('update-header' , {} )
      }

    },

    handleSelect(item , categoryName) {
      if ( item.topics ) {
        this.selectedCategory = item
        this.selectedCategoryName = categoryName
        this.$emit('update-header' , { name : categoryName , displayReturn : true } )
      } else {
        this.openArticle(item.alias)
      }
    },

    async initPage() {
      let result = await this.GET_CATEGORIES_PUBLIC('westudy')
      if ( !result || result === true) {
        return
      }
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>